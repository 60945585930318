.reports-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: #fff;
    
  }

  .reports-page {
    text-align: center;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: url('../assets/reports_background.png') no-repeat center center fixed;
    background-size: cover;
    color: white;
  }
  
  .reports-container h1 {
    text-align: center;
    font-size: 36px;
    margin-bottom: 40px;
    font-weight: 600;
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
    
  }
  
  .reports-section {
    margin-bottom: 60px;
    background: rgba(255, 255, 255, 0.08);
    border-radius: 20px;
    padding: 30px;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    /* background-color: rgba(186, 201, 188, 0.763); */
  }
  
  .reports-section h2 {
    font-size: 28px;
    margin-bottom: 25px;
    color: #fff;
    border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    padding-bottom: 10px;
  }
  
  /* Specifications */
  .specs-container {
    display: flex;
    gap: 40px;
  }
  
  .device-image-container {
    flex: 0 0 35%;
  }
  
  .device-image {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .specs-details {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .spec-item {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 10px;
    padding: 15px;
    border: 1px solid rgba(255, 255, 255, 0.15);
  }
  
  .spec-item h3 {
    font-size: 18px;
    margin-bottom: 8px;
    color: #4CAF50;
  }
  
  .spec-item p {
    margin: 0;
    font-size: 16px;
    line-height: 1.5;
  }
  
  /* Research section */
  .research-container {
    display: flex;
    gap: 40px;
  }
  
  .research-text {
    flex: 1;
    line-height: 1.6;
  }
  
  .research-text p {
    margin-bottom: 20px;
  }
  
  .research-chart {
    flex: 0 0 40%;
  }
  
  .chart-image {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  }
  
  .chart-caption {
    text-align: center;
    font-style: italic;
    margin-top: 10px;
    font-size: 14px;
    opacity: 0.8;
  }
  
  
  .back-to-home {
    text-align: center;
    margin-top: 40px;
    color: #ffffff;
    cursor: pointer;
    font-size: 16px;
    font-weight: 500;
    padding: 10px 20px;
    border-radius: 8px;
    display: inline-block;
    transition: all 0.3s ease;
    text-decoration: underline;
    opacity: 0.8;
  }
  
  .back-to-home:hover {
    opacity: 1;
    transform: translateY(-2px);
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
  
  /* Responsive design */
  @media (max-width: 992px) {
    .specs-details {
      grid-template-columns: 1fr;
    }
  }
  
  @media (max-width: 768px) {
    .specs-container,
    .research-container {
      flex-direction: column;
    }
    
    .device-image-container,
    .research-chart {
      margin-bottom: 20px;
    }
  }
.landing-page {
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: url('../assets/background.png') no-repeat center center fixed;
  background-size: cover;
  color: white;
}
  
  .landing-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .landing-logo {
      height: 20vmin;
    }
  }
  
  .landing-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    margin-top: -300px;
  }
  
  .cta-container {
    display: flex;
    gap: 20px;
    margin-top: 20px;
  }
  
  .cta-button {
    padding: 10px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .cta-button.primary {
    background-color: #4CAF50;
    color: white;
  }
  
  .cta-button.primary:hover {
    background-color: #45a049;
  }
  
  .cta-button.secondary {
    background-color: #f1f1f1;
    color: black;
  }
  
  .cta-button.secondary:hover {
    background-color: #ddd;
  }
  
  @keyframes landing-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
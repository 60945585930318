/* src/components/navbar/navbar.css */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  background-color: rgba(40, 44, 52, 0.9);
  color: white;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
  position: sticky;
  top: 0;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.nav-brand {
  cursor: pointer;
  transition: transform 0.2s ease;
}

.nav-brand:hover {
  transform: scale(1.02);
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 20px;
}

.nav-menu {
  display: flex;
  gap: 10px;
}

.nav-link {
  background: transparent;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.nav-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  transform: translateY(-2px);
}

.sign-in-btn {
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  padding: 8px 15px;
  font-size: 14px;
  font-weight: 500;
  transition: all 0.2s ease;
}

.sign-in-btn:hover {
  background-color: #45a049;
  transform: translateY(-2px);
}

/* Existing user menu styles */
.user-menu {
  position: relative;
  display: inline-block;
}

.user-name {
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  font-weight: 500;
}

.user-name::after {
  content: '▼';
  font-size: 10px;
  margin-left: 5px;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  color: black;
  border-radius: 5px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  z-index: 1;
  min-width: 150px;
  overflow: hidden;
  margin-top: 5px;
}

.dropdown-menu button {
  width: 100%;
  text-align: left;
  padding: 10px 15px;
  background-color: transparent;
  color: #333;
  border: none;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s ease;
  cursor: pointer;
}

.dropdown-menu button:last-child {
  border-bottom: none;
}

.dropdown-menu button:hover {
  background-color: #f5f5f5;
}

/* Contact Dropdown Styles */
.contact-dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  right: 0;
  background-color: #f9f9f9;
  min-width: 250px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 12px 16px;
  border-radius: 4px;
  color: #333;
  text-align: left;
}

.dropdown-content p {
  margin: 5px 0;
}

.dropdown-content a {
  color: #4CAF50;
  text-decoration: none;
}

.dropdown-content a:hover {
  text-decoration: underline;
}

.company-logo {
  width: 50px; /* Default size */
  height: auto;
  object-fit: contain;
}

/* Mobile responsive */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    padding: 15px;
    gap: 10px;
  }
  
  .nav-links {
    width: 100%;
    justify-content: center;
    flex-wrap: wrap;
  }
  
  .nav-menu {
    flex-wrap: wrap;
    justify-content: center;
  }

  .dropdown-content {
    position: static;
    width: 100%;
    margin-top: 5px;
  }
  
  .contact-dropdown {
    width: 100%;
  }
  
  .company-logo {
    width: 40px; /* Smaller on mobile */
  }
  
  .logo-container span {
    font-size: 14px; /* Smaller text on mobile */
  }
}

@media (max-width: 480px) {
  .company-logo {
    width: 30px; /* Even smaller on very small screens */
  }
}
/* src/components/paymentspage/payments.css */

/* Global styles */
.payments-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 40px 20px;
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #fff;
}

.payments-container h1 {
  display: flex;
  text-align: center;
  font-size: 36px;
  margin-bottom: 40px;
  font-weight: 600;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}

/* Device card */
.device-card {
  background: linear-gradient(145deg, #ffffff, #f5f7fa);
  margin-bottom: 50px;
  display: flex;
  align-items: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.device-card:hover {
  transform: translateY(-5px);
}

.device-image {
  max-width: 600px;
  margin-right: 50px;
  border-radius: 10px;
}

.product-details {
  text-align: center;
  color: #333;
}

.product-details h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #1e3a8a;
}

/* License tiers */
.license-container {
  margin-bottom: 80px;
}

.license-container h2 {
  font-size: 32px;
  margin-bottom: 30px;
  text-align: center;
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.tier-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 30px;
  margin: 30px 0;
}

.tier-card {
  background: linear-gradient(145deg, #ffffff, #f5f7fa);
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: #333;
  position: relative;
  overflow: hidden;
}

.tier-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, #4CAF50, #45a049);
}

.tier-card:hover {
  transform: translateY(-7px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.tier-card h3 {
  font-size: 24px;
  margin-bottom: 15px;
  color: #1e3a8a;
}

/* Divider */
.section-divider {
  height: 80px;
  width: 100%;
  margin: 30px 0;
  position: relative;
}

.section-divider::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  height: 1px;
  background: linear-gradient(90deg, rgba(255,255,255,0), rgba(255,255,255,0.5), rgba(255,255,255,0));
}

/* Additional clicks section */
.additional-clicks-section {
  margin-top: 50px;
  padding: 40px;
  background: rgba(255, 255, 255, 0.08);
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.section-title {
  font-size: 32px;
  margin-bottom: 30px;
  color: #fff;
  text-align: center;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.clicks-options {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  margin: 40px 0 20px;
}

.click-option {
  background: linear-gradient(145deg, #ffffff, #f5f7fa);
  border-radius: 15px;
  padding: 30px;
  text-align: center;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  color: #333;
}

.click-option:hover {
  transform: translateY(-7px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.15);
}

.click-option p {
  font-size: 18px;
  margin-bottom: 10px;
}

/* Buttons */
.payment-button {
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  background: linear-gradient(145deg, #4CAF50, #45a049);
  color: white;
  border: none;
  padding: 14px 28px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  margin-bottom: 50px;
  letter-spacing: 0.5px;
}

.payment-button:hover {
  background: linear-gradient(145deg, #45a049, #3d8b41);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

.payment-button:disabled {
  background: #cccccc;
  cursor: not-allowed;
  transform: none;
  box-shadow: none;
}

.price {
  font-size: 28px;
  font-weight: 700;
  color: #4CAF50;
  margin: 20px 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.price-2 {
  font-size: 15px;
  margin: 5px 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.preorder-details {
  display: flex;
  font-size: 25px;
  font-weight: 700;
  color: #4CAF50;
  margin: 20px 0;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.back-to-home {
  text-align: center;
  margin-top: 70px;
  margin-bottom: 20px;
  color: #ffffff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  padding: 10px 20px;
  border-radius: 8px;
  display: inline-block;
  transition: all 0.3s ease;
  text-decoration: underline;
  opacity: 0.8;
}

.back-to-home:hover {
  opacity: 1;
  transform: translateY(-2px);
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.cart-button {
  background: linear-gradient(145deg, #2b2b2b, #1e1e1e);
  color: white;
  border: none;
  padding: 14px 28px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  margin-bottom: 20px;
  margin-right: 10px;
  letter-spacing: 0.5px;
}

.cart-button:hover {
  background: linear-gradient(145deg, #333333, #222222);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-2px);
}

/* Cart toggle button */
.cart-toggle-button {
  background: linear-gradient(145deg, #2b2b2b, #1e1e1e);
  color: white;
  border: none;
  padding: 14px 28px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin-top: 15px;
  margin-right: 10px;
  letter-spacing: 0.5px;
}

.cart-toggle-button:hover {
  background: linear-gradient(145deg, #3a7bc8, #2a6cb8);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  transform: translateY(-2px);
}

/* Cart styles */
.cart-container {
  background: white;
  border-radius: 15px;
  padding: 30px;
  margin-bottom: 40px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12);
  color: #333;
}

.cart-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #1e3a8a;
}

.cart-items {
  margin-bottom: 30px;
}

.cart-item {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
  border-bottom: 1px solid #eee;
  align-items: center;
}

.item-info h3 {
  margin: 0 0 5px 0;
  color: #333;
}

.quantity-button {
  background-color: #f5f7fa;
  border: 1px solid #ddd;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  font-size: 16px;
  cursor: pointer;
  margin: 0 12px;
  transition: all 0.2s ease;
}

.quantity-button:hover {
  background-color: #e9ecef;
}

.quantity {
  display: inline-block;
  min-width: 30px;
  text-align: center;
  font-weight: 600;
}

.remove-button {
  background-color: #ff6b6b;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 6px;
  cursor: pointer;
  margin-left: 20px;
  transition: all 0.2s ease;
}

.remove-button:hover {
  background-color: #ff5252;
}

.auth-note {
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  margin-top: 10px;
  font-style: italic;
}

.clicks {
  font-size: 20px;
  margin: 15px 0;
  font-weight: 600;
}

.supported-breeds {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  padding: 15px;
  margin: 20px 0;
  border: 1px dashed rgba(255, 255, 255, 0.2);
}

.supported-breeds h4 {
  margin: 0 0 10px 0;
  color: #45a049;
  font-size: 16px;
}

.breeds-list {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

.breeds-list li {
  background: rgba(76, 175, 80, 0.15);
  padding: 5px 12px;
  border-radius: 15px;
  font-size: 14px;
  border: 1px solid rgba(76, 175, 80, 0.3);
}

/* Disabled styles */
.tier-card.disabled, .click-option.disabled {
  opacity: 0.7;
  position: relative;
}

.tier-card.disabled::after, .click-option.disabled::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
  pointer-events: none;
}

.coming-soon-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 8px 16px;
  border-radius: 4px;
  z-index: 2;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
}

button:disabled {
  opacity: 0.6;
  cursor: not-allowed;
}

.availability-notice {
  text-align: center;
  padding: 10px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
  border: 1px dashed rgba(255, 255, 255, 0.3);
}

.availability-notice p {
  margin: 0;
  font-size: 16px;
  color: #fff;
}

.availability-notice strong {
  color: #4CAF50;
}

.flashing-text {
  display: inline-block;
  color: #ff6b00; /* Orange color for attention */
  font-weight: bold;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: rgba(255, 107, 0, 0.1);
  text-shadow: 0 0 5px rgba(255, 107, 0, 0.5);
}

.device-promotion {
  background-color: rgba(76, 175, 80, 0.1);
  border: 1px solid rgba(76, 175, 80, 0.3);
  border-radius: 8px;
  padding: 10px;
  margin: 15px 0;
  text-align: center;
  width: 500px;
}

.device-promotion strong {
  color: #4CAF50;
  width: 450px;
}

.payment-options {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 10px;
  margin: 30px 0;
  text-align: center;
  border: 1px solid #e0e0e0;
}

.sensor-title{
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}

.payment-options h3 {
  margin-top: 0;
  color: #333;
}

.payment-methods {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 20px 0;
}

.payment-methods label {
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #555;
}

.payment-method-text{
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  cursor: pointer;
}

.report-button {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.payment-methods input {
  margin-right: 10px;
}

/* Terms and Conditions Styling */
.terms-container {
  margin: 15px 0;
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 15px;
  border: 1px solid #e0e0e0;
}

.terms-checkbox-label {
  display: flex;
  align-items: flex-start;
  font-size: 14px;
  color: #555;
  line-height: 1.4;
}

.terms-checkbox {
  margin-right: 8px;
  margin-top: 3px;
}

.terms-link {
  color: #4a90e2;
  text-decoration: underline;
}

.terms-link:hover {
  text-decoration: none;
}

.terms-popup-trigger {
  background: none;
  border: none;
  color: #4a90e2;
  text-decoration: underline;
  cursor: pointer;
  font-size: inherit;
  padding: 0 5px;
  margin: 0 3px;
  font-family: inherit;
}

.terms-popup-trigger:hover {
  color: #3a7bc8;
}

/* Popup Styles */
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  border-radius: 10px;
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.popup-header {
  padding: 15px 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f9fa;
}

.popup-header h2 {
  margin: 0;
  color: #333;
  font-size: 20px;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  color: #555;
  cursor: pointer;
  padding: 0;
  line-height: 1;
}

.popup-body {
  padding: 20px;
  overflow-y: auto;
  color: #333;
  flex: 1;
  max-height: 70vh;
}

.popup-body h3 {
  margin-top: 20px;
  margin-bottom: 10px;
  color: #2c3e50;
  font-size: 18px;
}

.popup-body p {
  margin: 0 0 15px;
  line-height: 1.6;
}

.popup-body ul {
  margin: 0 0 15px 20px;
  padding: 0;
}

.popup-body li {
  margin-bottom: 8px;
  line-height: 1.5;
}

.popup-footer {
  padding: 15px 20px;
  border-top: 1px solid #eee;
  display: flex;
  justify-content: flex-end;
  background-color: #f8f9fa;
}

.popup-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 8px 15px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
}

.popup-button:hover {
  background-color: #45a049;
}

/* Responsive adjustments */
@media (max-width: 992px) {
  .tier-cards {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .payments-container h1 {
    font-size: 32px;
  }
}

@media (max-width: 768px) {
  .report-button {
    margin: 35px auto 50px auto; /* Increased top margin from 15px to 35px */
    width: fit-content;
    min-width: 200px;
    position: relative;
    top: 15px; /* Added additional top spacing */
  }
  .sensor-title{
    width: fit-content;
    min-width: 200px;
  }
  .payments-container {
    padding: 20px 15px;
  }
  
  .payments-container h1 {
    font-size: 28px;
    justify-content: center;
  }
  
  .device-card {
    flex-direction: column;
    text-align: center;
  }
  
  .device-image {
    margin-right: 0;
    margin-bottom: 30px;
    max-width: 100%;
  }
  
  .product-details {
    width: 100%;
    padding: 15px;
  }
  
  .device-promotion {
    width: 100%;
  }
  
  .device-promotion strong {
    width: auto;
  }
  
  .tier-cards,
  .clicks-options {
    grid-template-columns: 1fr;
    gap: 20px;
  }
  
  .payment-methods {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .payment-methods label {
    padding: 8px 0;
  }
  
  .cart-button, 
  .cart-toggle-button {
    width: 100%;
    margin-right: 0;
    margin-bottom: 10px;
  }
  
  .cart-item {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  
  .remove-button {
    margin-left: 0;
    margin-top: 10px;
  }
  
  @media (max-width: 768px) {
    .payment-button {
      margin: 15px auto 50px auto;
      width: fit-content;
      min-width: 200px;
    }
  }
  
  .section-title {
    font-size: 28px;
  }
  
  .additional-clicks-section {
    padding: 20px;
  }

  .popup-content {
    width: 95%;
    max-height: 80vh;
  }
  
  .popup-header h2 {
    font-size: 18px;
  }
  
  .popup-body {
    padding: 15px;
    font-size: 14px;
  }
  
  .popup-body h3 {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .payment-method-name {
    font-size: 14px;
  }
  
  .payment-method-fee {
    font-size: 11px;
  }
}

@media (max-width: 480px) {
  .payments-container h1 {
    font-size: 24px;
  }
  
  .price {
    font-size: 24px;
  }
  
  .preorder-details {
    font-size: 20px;
    justify-content: center;
  }
  
  .tier-card, 
  .click-option {
    padding: 20px 15px;
  }
  
  .supported-breeds h4 {
    font-size: 14px;
  }
  
  .breeds-list li {
    font-size: 12px;
    padding: 4px 8px;
  }
  
  .terms-container {
    padding: 12px 10px;
  }
  
  .terms-checkbox-label {
    font-size: 13px;
  }
  
  button {
    white-space: normal;
    height: auto;
  }
}

/* Make images responsive */
img {
  max-width: 100%;
  height: auto;
}

/* Prevent horizontal overflow */
html, body {
  overflow-x: hidden;
}

/* Payment method styles */
.payment-method-label {
  display: flex;
  align-items: flex-start;
  margin-bottom: 10px;
  cursor: pointer;
}

.payment-method-container {
  display: flex;
  align-items: flex-start;
}

.payment-method-text {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.payment-method-name {
  font-weight: 500;
  line-height: 1.2;
}

.payment-method-fee {
  font-size: 12px;
  color: #666;
  margin-top: 2px;
}
.back-to-home {
  margin-top: 20px;
  text-align: center;
  color: white;
  cursor: pointer;
  font-size: 14px;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 50px auto;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px #0d0d0d1a;
  background-color: rgba(186, 201, 188, 0.763); /* Adjusted background color for 40% transparency */
  color: #0d0d0d;
  justify-content: center;
}

.about-the-sensor {
  display: flex;
  text-align: justify;
  align-items: center;
  color: rgb(0, 0, 0);
}